.aboutUs-section {
  padding: 100px 0;

  @include smallDesktop {
    padding: 50px 0;
  }

  .aboutUs__top--paragraph {
    font-size: 20px;
    text-align: center;
    margin-top: 20px;

    @include tablet {
      font-size: 16px;
    }
  }

  .aboutUs-body {
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include tablet {
      grid-template-columns: repeat(1, 1fr);
      gap: 45px;
      margin-top: 50px;
    }

    .aboutUs__logo {
      text-align: center;
      max-width: 512px;
      margin: 0 auto;
      .aboutUs__logo--img {
        width: 100%;
      }
    }
    .aboutUs__text {
      padding-left: 70px;
      line-height: 1.5;
      @include tablet {
        padding-left: 0;
        text-align: center;
      }
      .aboutUs__text--paragraph {
        font-size: 18px;
        max-width: 80%;
        margin: 0 auto;

        @include tablet {
          font-size: 15px;
        }
      }
    }
  }
}
